import React from 'react';
import PropTypes from 'prop-types';
import './Publications.css';

function DoiItem(props) {
    return (
        <div>
            DOI: <a href={`https://doi.org/${props.id}`}>{props.id}</a>{' '}
            <div
                className="altmetric-embed align-text-bottom"
                data-badge-popover="right"
                data-badge-type="4"
                data-doi={props.id}
            />
        </div>
    );
}

DoiItem.propTypes = {
    id: PropTypes.string.isRequired,
};

export default function Publications() {
    return (
        <div>
            <h4 id="index">Index</h4>
            <ul>
                <li>
                    <a href="#papers">Journal publications</a>
                </li>
                <li>
                    <a href="#conferences">Conference papers</a>
                </li>
                <li>
                    <a href="#policy">Policy documents</a>
                </li>
                <li>
                    <a href="#theses">Theses</a>
                </li>
            </ul>

            <h4 id="papers">Journal publications</h4>
            <ol>
                <li>
                    {' '}
                    van &apos;t Hoff, J., Hauglustaine, D., Pletzer, J.,
                    Skowron, A., Grewe, V., Matthes, S., Meuser, M.M., Thor,
                    R.N., <b>Dedoussi, I.C.</b> (2025) “Multi-model assessment
                    of the atmospheric and radiative effects of supersonic
                    transport aircraft.”{' '}
                    <i>Atmospheric Chemistry and Physics</i>, [In Press].
                    <DoiItem id="10.5194/egusphere-2024-2866" />
                </li>
                <li>
                    {' '}
                    van &apos;t Hoff, J., Grewe, V., <b>Dedoussi, I.C.</b>{' '}
                    (2024) “Sensitivities of ozone and radiative forcing to
                    supersonic aircraft emissions across two flight corridors.”{' '}
                    <i>Journal of Geophysical Research: Atmospheres</i>, 129,
                    22, e2023JD040476.
                    <DoiItem id="10.1029/2023JD040476" />
                </li>
                <li>
                    {' '}
                    Rao, P., Dwight, R., Singh, D., Maruhashi, J.,{' '}
                    <b>Dedoussi, I.C.</b>, Grewe, V., Frömming, C. (2024) “The
                    ozone radiative forcing of nitrogen oxide emissions from
                    aviation can be estimated using a probabilistic approach.”{' '}
                    <i>Communications Earth & Environment </i>, 5, 558.
                    <DoiItem id="10.1038/s43247-024-01691-2" />
                </li>
                <li>
                    {' '}
                    <b>Dedoussi, I.C.</b>, Henze, D.K., Eastham, S.D., Speth,
                    R.L., Barrett, S.R.H. (2024) “Development of the adjoint of
                    the unified tropospheric–stratospheric chemistry extension
                    (UCX) in GEOS-Chem adjoint v36.”{' '}
                    <i>Geoscientific Model Development</i>, 17, 5689–5703.
                    <DoiItem id="10.5194/gmd-17-5689-2024" />
                </li>
                <li>
                    {' '}
                    Maruhashi, J., Mertens, M., Grewe, V., <b>
                        Dedoussi, I.C.
                    </b>{' '}
                    (2024) “A multi-method assessment of the regional
                    sensitivities between flight altitude and short-term O
                    <sub>3</sub> climate warming from aircraft NO<sub>x</sub>{' '}
                    emissions.” <i>Environmental Research Letters</i>, 19,
                    054007.
                    <DoiItem id="10.1088/1748-9326/ad376a" />
                </li>
                <li>
                    {' '}
                    Henneman, L.R.F., Choirat, C., <b>Dedoussi, I.C.</b>,
                    Dominici, F., Roberts, J., Zigler, C.M. (2023) “Mortality
                    risk from United States coal electricity generation.”{' '}
                    <i>Science</i> 382 (6673), 941-946.
                    <DoiItem id="10.1126/science.adf4915" />
                </li>
                <li>
                    {' '}
                    Quadros, F.D.A., van Loo, M., Snellen, M.,{' '}
                    <b>Dedoussi, I.C.</b> (2023) “Nitrogen deposition from
                    aviation emissions.” <i>Science of the Total Environment</i>
                    , 858, 3, 159855.
                    <DoiItem id="10.1016/j.scitotenv.2022.159855" />
                </li>
                <li>
                    {' '}
                    Maruhashi, J., Grewe, V., Frömming, C., Jöckel, P.,{' '}
                    <b>Dedoussi, I.C.</b> (2022) “Transport patterns of global
                    aviation NO<sub>x</sub> and their short-term O<sub>3</sub>{' '}
                    radiative forcing – a machine learning approach.”{' '}
                    <i>Atmospheric Chemistry and Physics</i> 22, 14253–14282.
                    <DoiItem id="10.5194/acp-22-14253-2022" />
                </li>
                <li>
                    {' '}
                    Quadros, F.D.A., Snellen, M., Sun, J., <b>
                        Dedoussi, I.C.
                    </b>{' '}
                    (2022) “Global civil aviation emissions estimates for
                    2017-2020 using ADS-B data.” <i>Journal of Aircraft</i> 59,
                    6, 1394-1405.
                    <DoiItem id="10.2514/1.C036763" />
                </li>
                <li>
                    {' '}
                    Fritz, T.M., <b>Dedoussi, I.C.</b>, Eastham, S.D., Speth,
                    R.L., Henze, D.K., Barrett, S.R.H. (2022) “Identifying the
                    ozone-neutral aircraft cruise altitude.”{' '}
                    <i>Atmospheric Environment</i> 276, 119057.
                    <DoiItem id="10.1016/j.atmosenv.2022.119057" />
                </li>
                <li>
                    {' '}
                    Verhoeven, V.B., <b>Dedoussi, I.C.</b> (2022) “Annual
                    satellite-based NDVI-derived land cover of Europe for
                    2001-2019.” <i>Journal of Environmental Management</i> 302A,
                    15, 113917.
                    <DoiItem id="10.1016/j.jenvman.2021.113917" />
                </li>
                <li>
                    {' '}
                    <b>Dedoussi, I.C.</b> (2021) “Implications of future
                    atmospheric composition in decision-making for sustainable
                    aviation.” <i>Environmental Research Letters</i> 16, 031002.
                    <DoiItem id="10.1088/1748-9326/abe74d" />
                </li>
                <li>
                    {' '}
                    Valera-Medina, A., Amer-Hatem, F., Azad, A. K.,{' '}
                    <b>Dedoussi, I.C.</b>, de Joannon, M., Fernandes, R. X.,
                    Glarborg, P., Hashemi, H., He, X., Mashruk, S., McGowan, J.,
                    Mounaim-Rouselle, C., Ortiz-Prado, A., Ortiz-Valera, A.,
                    Rossetti, I., Shu, B., Yehia, M., Xiao, H., Costa, M. (2021)
                    “Review on Ammonia as a Potential Fuel: From Synthesis to
                    Economics.” <i>Energy & Fuels</i> 35, 9, 6964-7029.
                    <DoiItem id="10.1021/acs.energyfuels.0c03685" />
                </li>
                <li>
                    {' '}
                    Henneman, L.R.F., <b>Dedoussi, I.C.</b>, Casey, J.A.,
                    Choirat, C., Barrett, S.R.H., Zigler, C.M. (2021)
                    “Comparisons of simple and complex methods for quantifying
                    exposure to individual point source air pollution
                    emissions.”{' '}
                    <i>
                        Journal of Exposure Science & Environmental Epidemiology
                    </i>{' '}
                    31, 654-663.
                    <DoiItem id="10.1038/s41370-020-0219-1" />
                </li>
                <li>
                    {' '}
                    Quadros, F.D.A., Snellen, M., <b>Dedoussi, I.C.</b> (2020)
                    “Regional sensitivities of air quality and human health
                    impacts to aviation emissions.”{' '}
                    <i>Environmental Research Letters</i> 15, 105013.
                    <DoiItem id="10.1088/1748-9326/abb2c5" />
                </li>
                <li>
                    {' '}
                    <b>Dedoussi, I.C.</b>, Eastham, S.D., Μonier, E., Barrett,
                    S.R.H. (2020) “Premature mortality related to United States
                    cross-state air pollution.” <i>Nature</i> 578, 261-265.
                    <DoiItem id="10.1038/s41586-020-1983-8" />
                </li>
                <li>
                    Grobler, C., Wolfe, P.J., Dasadhikari, K.,{' '}
                    <b>Dedoussi, I.C.</b>, Allroggen, F., Speth, R.L., Eastham,
                    S.D., Agarwal, A., Staples, M.D., Sabnis, J., Barrett,
                    S.R.H. (2019) “Marginal climate and air quality costs of
                    aviation emissions.” <i>Environmental Research Letters</i>{' '}
                    14, 114031.
                    <DoiItem id="10.1088/1748-9326/ab4942" />
                </li>
                <li>
                    <b>Dedoussi, I.C.</b>, Allroggen, F., Flanagan, R., Hansen,
                    T., Taylor, B., Barrett, S.R.H., Boyce, J.K. (2019) “The
                    co-pollutant cost of carbon emissions: an analysis of the
                    U.S. electric power generation sector.”{' '}
                    <i>Environmental Research Letters</i> 14, 094004.
                    <DoiItem id="10.1088/1748-9326/ab34e3" />
                </li>
                <li>
                    Chossière, G.P., Malina, R., Ashok, A.,{' '}
                    <b>Dedoussi, I.C.</b>, Eastham, S.D., Speth, R.L., Barrett,
                    S.R.H. (2017) “Public health impacts of excess NO
                    <sub>x</sub> emissions from Volkswagen diesel passenger
                    vehicles in Germany.” <i>Environmental Research Letters</i>{' '}
                    12, 034014.
                    <DoiItem id="10.1088/1748-9326/aa5987" />
                </li>
                <li>
                    Barrett, S.R.H., Speth, R.L., Eastham, S.D.,{' '}
                    <b>Dedoussi, I.C.</b>, Ashok, A., Malina, R., Keith, D.
                    (2015) “Impact of the Volkswagen emissions control defeat
                    device on US public health.”{' '}
                    <i>Environmental Research Letters</i> 10, 114005.
                    <DoiItem id="10.1088/1748-9326/10/11/114005" />
                    {'['}&quot;Best article of 2015&quot; award{']'}
                </li>
                <li>
                    <b>Dedoussi, I.C.</b>, Barrett, S.R.H. (2014) “Air pollution
                    and early deaths in the United States. Part II: Attribution
                    of PM<sub>2.5</sub> exposure to emissions species, time,
                    location and sector.” <i>Atmospheric Environment</i> 99, pp.
                    610-617.
                    <DoiItem id="10.1016/j.atmosenv.2014.10.033" />
                </li>
                <li>
                    Ashok, A., <b>Dedoussi, I.C.</b>, Yim, S.H.L, Balakrishnan,
                    H., Barrett, S.R.H. (2014) “Quantifying the air quality-CO
                    <sub>2</sub> tradeoff potential for airports.”{' '}
                    <i>Atmospheric Environment</i> 99, pp. 546-555.
                    <DoiItem id="10.1016/j.atmosenv.2014.10.024" />
                </li>
            </ol>
            <h4 id="conferences">Conference papers</h4>
            <ol>
                <li>
                    Quadros, F.D.A., Snellen, M., <b>Dedoussi, I.C.</b> (2022)
                    “Recent and projected trends in global civil aviation NO
                    <sub>x</sub> emission indices” <i>AIAA SciTech</i>, 3-7
                    January 2022, San Diego, USA & Virtual. AIAA 2022-2051.
                    <DoiItem id="10.2514/6.2022-2051" />
                </li>
                <li>
                    Sun, J., <b>Dedoussi, I.C.</b> (2021) “Evaluation of
                    aviation emissions and environmental costs in Europe using
                    OpenSky and OpenAP” presented at the{' '}
                    <i>OpenSky Symposium</i>, 18-19 November 2021, Brussels,
                    Belgium & Virtual, published in{' '}
                    <i>Engineering Proceedings</i>, 13 (1), 5.
                    <DoiItem id="10.3390/engproc2021013005" />
                </li>
                <li>
                    <b>Dedoussi, I.C.</b>, Hynes, T.P., Siller, H. (2013)
                    “Investigating landing gear noise using fly-over data: the
                    case of a Boeing 747-400.”{' '}
                    <i>
                        19<sup>th</sup> AIAA/CEAS Aeroacoustics Conference (34
                        <sup>th</sup> AIAA Aeroacoustics Conference)
                    </i>{' '}
                    27-29 May 2013, Berlin, Germany. AIAA 2013-2115.
                    <DoiItem id="10.2514/6.2013-2115" />
                </li>
            </ol>
            <h4 id="policy">
                Contributions to policy documents &amp; white papers
            </h4>
            <ol>
                <li>
                    Accelerating the path towards carbon-free aviation,{' '}
                    <a href="https://d2k0ddhflgrk1i.cloudfront.net/LR/Actueel/News/2022/Q3/Accelerating%20the%20path%20towards%20carbon-free%20aviation%20%28002%29.pdf">
                        Sustainable and Energy Efficient Aviation CoE &amp;
                        Aeronautics Research Centre Niedersachsen
                    </a>
                    , July 2022 [Author].
                </li>
                <li>
                    MER 3456. Evaluation of nitrogen calculations for Lelystad
                    Airport (Evaluatie stikstofberekeningen Lelystad Airport),{' '}
                    <a href="https://www.commissiemer.nl/adviezen/3456">
                        Dutch Commission for Environmental Assessments
                    </a>
                    , Commissie voor de milieueffectrapportage (
                    <i>Commissie MER</i>), Utrecht, March 2020 [Contributor].
                </li>
                <li>
                    Advisory Board on Nitrogen Problem: Advice on the aviation
                    sector (Adviescollege Stikstofproblematiek: Advies
                    luchtvaartsector),{' '}
                    <a href="https://www.rijksoverheid.nl/documenten/rapporten/2020/01/14/advies-luchtvaartsector">
                        Dutch Advisory Board on Nitrogen Problem
                    </a>
                    , <i>Adviescollege Stikstofproblematiek</i>, The Hague,
                    January 2020 [Expert panel member].
                </li>
                <li>
                    ICAO-CAEP/10 Agenda Item 5: CO<sub>2</sub> Standard
                    development. CAEP/10-IP/24. “Global cost-benefit analysis of
                    ICAO CO<sub>2</sub> standard stringency options.”{' '}
                    <i>ICAO</i>, Montréal, February 2016 [Contributor].
                </li>
            </ol>
            <h4 id="theses">Theses</h4>
            <ol>
                <li>
                    <b>Dedoussi, I.C.</b> (2018) “Adjoint sensitivity analysis
                    of the atmospheric impacts of combustion emissions.”
                    Massachusetts Institute of Technology, Department of
                    Aeronautics &amp; Astronautics, <i>Ph.D. Thesis.</i>{' '}
                    <a href="https://hdl.handle.net/1721.1/120414">
                        MIT DSpace
                    </a>
                    .
                </li>
                <li>
                    <b>Dedoussi, I.C.</b> (2014) “Air pollution and early deaths
                    in the United States: Attribution of PM<sub>2.5</sub>{' '}
                    exposure to emissions species, time, location and sector.”
                    Massachusetts Institute of Technology, Department of
                    Aeronautics &amp; Astronautics, <i>S.M. Thesis.</i>{' '}
                    <a href="https://hdl.handle.net/1721.1/90658">MIT DSpace</a>
                    .
                </li>
                <li>
                    <b>Dedoussi, I.C.</b> (2012) “Landing gear noise analysis of
                    a Boeing 747-400”. Department of Engineering, University of
                    Cambridge, <i>M.Eng. Thesis</i>.
                </li>
            </ol>
        </div>
    );
}
